import React, { useCallback } from 'react';

import { Box } from '@vk-hr-tek/ui/Box';
import { CircularProgress } from '@vk-hr-tek/ui/CircularProgress';
import { Typography } from '@vk-hr-tek/ui/Typography';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useInject } from '@vk-hr-tek/core/ioc';
import { selectAuthInstance } from '@vk-hr-tek/app/auth/slice';
import { AuthConfigService } from '@vk-hr-tek/app/auth/services';

import { useSelector } from '@app/hooks';

import { Page } from '../../../ui/page';
import { Logo } from '../../../../layout/ui/Logo';
import { Button } from '../../../ui/Button';

export const Login = () => {
  const config = useInject(AuthConfigService);
  const isDesktop = useIsDesktop();

  const { button_color: buttonColor, button_text: buttonText } =
    useSelector(selectAuthInstance);

  const oauth2AutoSubmit =
    window.REACT_APP_VKHRTEK_OAUTH2_AUTO_SUBMIT.toString() === 'true';

  const formRef = useCallback(
    (element: HTMLFormElement) => {
      if (oauth2AutoSubmit && element) {
        element.submit();
      }
    },
    [oauth2AutoSubmit],
  );

  return (
    <Page title="" theme="oauth2">
      <form
        action={`${config.getApiUrl()}/oauth2/auth_form/init`}
        method="POST"
        ref={formRef}
      >
        <Box display="flex" flexDirection="column" alignItems="center">
          <Logo variant={isDesktop ? '80' : '72'} />
          <Box
            mt={{ xs: '32', md: '40' }}
            mb={{ xs: '40', md: '48' }}
            gap={isDesktop ? '16' : '8'}
            display="flex"
            flexDirection="column"
            alignItems="center"
          >
            <Typography variant={isDesktop ? 'h4' : 'h5'} align="center">
              Добро пожаловать!
            </Typography>
            <Typography variant={isDesktop ? 'body1' : 'body3'} align="center">
              Это сервис кадрового электронного документооборота (КЭДО)
            </Typography>
          </Box>
          <input type="hidden" value="false" name="phone_change" />
          <input type="hidden" value="keycloak" name="source" />
          {oauth2AutoSubmit ? (
            <CircularProgress size={50} />
          ) : (
            <Button
              color={buttonColor && `#${buttonColor}`}
              fullWidth={!isDesktop}
            >
              {buttonText || 'Войти'}
            </Button>
          )}
        </Box>
      </form>
    </Page>
  );
};
