import { makeStyles, Theme, darken } from '@material-ui/core';

export const useStyles = makeStyles<
  Theme,
  { color?: string; fullWidth: boolean }
>(({ tokens, px }) => ({
  button: ({ color, fullWidth }) => ({
    position: 'relative',
    paddingTop: tokens.spacing['16'],
    paddingLeft: tokens.spacing['24'],
    paddingBottom: tokens.spacing['16'],
    paddingRight: tokens.spacing['24'],
    height: px(56),
    width: fullWidth ? '100%' : 'auto',
    fontWeight: 600,
    fontSize: tokens.typography.variant.body1.fontSize,
    LineHeight: '1.5em',
    color: tokens.colors.text.dark.primary,
    backgroundColor: color || tokens.colors.original.brand.primary,
    border: 'none',
    borderRadius: tokens.radius.l,
    cursor: 'pointer',

    '&:hover': {
      backgroundColor: color && darken(color, 0.1),
    },

    '&:disabled': {
      backgroundColor: tokens.colors.bg.greayscale.primary,
      color: tokens.colors.text.light.tertirary,
      cursor: 'default',
    },
  }),

  loading: {
    position: 'absolute',
    top: 'calc(50% - 9.4px)',
    left: 'calc(50% - 9.4px)',
  },
}));
