import { interfaces } from 'inversify';

import { container as containerDefault } from '@vk-hr-tek/core/ioc';
import { RoleService, TokenService } from '@vk-hr-tek/core/http';
import { ListService } from '@vk-hr-tek/core/filter/types';
import {
  GroupingStrategy,
  NotificationsMapper,
  NotificationsEventEmitter,
} from '@vk-hr-tek/core/notifications/types';
import {
  Logger,
  ConsoleLogger,
  SentryLogger,
  User,
} from '@vk-hr-tek/core/logger';
import { History } from '@vk-hr-tek/core/history';
import {
  LocalStorage,
  LocalStorageService,
} from '@vk-hr-tek/core/local-storage';
import { RedirectPageService } from '@vk-hr-tek/app/auth/services/common';
import { UpdatorService } from '@vk-hr-tek/core/updator';
import { DownloadNotificationEventEmitter } from '@vk-hr-tek/core/download-notification/types';

import { AuthTokenService, AuthOpenIdService } from '../auth/services';
import { CandidateRouter } from '../candidate/types';
import { CandidatesRouter } from '../candidates/types';
import { EmployeesRouter } from '../employees/types';
import { EventsRouter } from '../events/types';
import { EventNotificationsMapper } from '../events/mappers';
import {
  EventsListService,
  EventNotificationService,
} from '../events/services';
import { OrganizationRouter } from '../organization/types';
import { PolicyRouter } from '../policy/types';
import { RedirectPage } from '../auth/types';
import {
  SettingsRouter,
  SettingsAttorneysRouter,
  SettingsAttorneysUsersRouter,
  SettingsGroupsRouter,
  SettingsCompanyRouter,
  SettingsEventTypesRouter,
  SettingsSubstitutesRouter,
  SettingsTemplatesRouter,
  SettingsVacationsRouter,
  SettingsCompanyUnepTypesRouter,
} from '../settings/types';
import { UserRoleService, UserEntityService } from '../user/services';
import { UserRouter } from '../user/types';
import { VacationsRouter } from '../vacations/types';

import {
  CandidatesRouterService,
  CandidateRouterService,
  EmployeesRouterService,
  EventsRouterService,
  OrganizationRouterService,
  PolicyRouterService,
  RouterServices,
  SettingsAttorneysRouterService,
  SettingsAttorneysUsersRouterService,
  SettingsCompanyUnepTypesRouterService,
  SettingsCompanyRouterService,
  SettingsEventTypesRouterService,
  SettingsGroupsRouterService,
  SettingsRouterService,
  SettingsSubstitutesRouterService,
  SettingsTemplatesRouterService,
  SettingsVacationsRouterService,
  UserRouterService,
  VacationsRouterService,
} from './router';

const emptyNotificationsEmitter: NotificationsEventEmitter = {
  onShow: () => {},
};

const emptyDownloadNotificationsEmitter: DownloadNotificationEventEmitter = {
  onShow: () => {},
  onCancel: () => {},
};

interface InitOptions {
  history: History;
  router?: RouterServices;
  notifications?: NotificationsEventEmitter;
  downloadNotifications?: DownloadNotificationEventEmitter;
  localStorageNamespace?: string;
  role?: RoleService;
  redirectPageService?: RedirectPage;
  auth?: TokenService;
  updator?: UpdatorService;
  container?: typeof containerDefault;
}

export const init = ({
  container = containerDefault,
  router,
  notifications = emptyNotificationsEmitter,
  downloadNotifications = emptyDownloadNotificationsEmitter,
  history,
  role,
  redirectPageService,
  auth,
  localStorageNamespace = '',
  updator,
}: InitOptions) => {
  container.unbindAll();
  container.bind<History>(History).toConstantValue(history);
  container
    .bind<LocalStorage>(LocalStorage)
    .toConstantValue(new LocalStorageService(localStorageNamespace));

  container.bind<ListService>(ListService).to(EventsListService);

  if (role) {
    container.bind<RoleService>(RoleService).toConstantValue(role);
  } else {
    container.bind<RoleService>(RoleService).to(UserRoleService);
  }

  if (redirectPageService) {
    container
      .bind<RedirectPage>(RedirectPage)
      .toConstantValue(redirectPageService);
  } else {
    container.bind<RedirectPage>(RedirectPage).to(RedirectPageService);
  }

  container.bind<TokenService>(TokenService).to(AuthTokenService);

  if (auth && auth.get && auth.restore) {
    container.bind<AuthOpenIdService>(AuthOpenIdService).toConstantValue(auth);
  }

  container.bind<User>(User).to(UserEntityService);

  container
    .bind<Logger>(Logger)
    .to(window.REACT_APP_VKHRTEK_SENTRY_URL ? SentryLogger : ConsoleLogger);

  if (updator) {
    container.bind<UpdatorService>(UpdatorService).toConstantValue(updator);
  } else {
    container.bind<UpdatorService>(UpdatorService).to(UpdatorService);
  }

  container
    .bind<GroupingStrategy>(GroupingStrategy)
    .to(EventNotificationService);
  container
    .bind<NotificationsMapper>(NotificationsMapper)
    .to(EventNotificationsMapper);

  container
    .bind<NotificationsEventEmitter>(NotificationsEventEmitter)
    .toConstantValue({
      ...emptyNotificationsEmitter,
      ...('onShow' in notifications ? notifications : {}),
    });

  container
    .bind<DownloadNotificationEventEmitter>(DownloadNotificationEventEmitter)
    .toConstantValue({
      ...emptyDownloadNotificationsEmitter,
      ...('onShow' in downloadNotifications ? downloadNotifications : {}),
    });

  container
    .bind<CandidatesRouter>(CandidatesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const candidatesRouter = new CandidatesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(candidatesRouter, {
        get(target, method: keyof CandidatesRouter) {
          if (router?.candidates && method in router.candidates) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.candidates?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container.bind<CandidateRouter>(CandidateRouter).to(CandidateRouterService);

  container
    .bind<EmployeesRouter>(EmployeesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const employeesRouter = new EmployeesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(employeesRouter, {
        get(target, method: keyof EmployeesRouter) {
          if (router?.employees && method in router.employees) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.employees?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<EventsRouter>(EventsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const eventsRouter = new EventsRouterService(
        context.container.get(History),
      );

      return new Proxy(eventsRouter, {
        get(target, method: keyof EventsRouter) {
          if (router?.events && method in router.events) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.events?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<OrganizationRouter>(OrganizationRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const organisationRouter = new OrganizationRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(organisationRouter, {
        get(target, method: keyof OrganizationRouter) {
          if (router?.organization && method in router.organization) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.organization?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<PolicyRouter>(PolicyRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const policyRouter = new PolicyRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(policyRouter, {
        get(target, method: keyof PolicyRouter) {
          if (router?.policy && method in router.policy) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.policy?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsAttorneysRouter>(SettingsAttorneysRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsAttorneysRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsAttorneysRouter) {
          if (router?.attorneysSettings && method in router.attorneysSettings) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.attorneysSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsAttorneysUsersRouter>(SettingsAttorneysUsersRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsAttorneysUsersRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsAttorneysUsersRouter) {
          if (
            router?.attorneysUsersSettings &&
            method in router.attorneysUsersSettings
          ) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.attorneysUsersSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsCompanyUnepTypesRouter>(SettingsCompanyUnepTypesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsCompanyUnepTypesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsCompanyUnepTypesRouter) {
          if (
            router?.companyUnepTypeSettings &&
            method in router.companyUnepTypeSettings
          ) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.companyUnepTypeSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsEventTypesRouter>(SettingsEventTypesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsEventTypesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsEventTypesRouter) {
          if (
            router?.eventTypesSettings &&
            method in router.eventTypesSettings
          ) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.eventTypesSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsGroupsRouter>(SettingsGroupsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsGroupsRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsGroupsRouter) {
          if (router?.groupsSettings && method in router.groupsSettings) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.groupsSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsRouter>(SettingsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsRouter) {
          if (router?.settings && method in router.settings) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.settings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsSubstitutesRouter>(SettingsSubstitutesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsSubstitutesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsSubstitutesRouter) {
          if (
            router?.substitutesSettings &&
            method in router.substitutesSettings
          ) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.substitutesSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsTemplatesRouter>(SettingsTemplatesRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsTemplatesRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsTemplatesRouter) {
          if (router?.templatesSettings && method in router.templatesSettings) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.templatesSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<SettingsVacationsRouter>(SettingsVacationsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsRouter = new SettingsVacationsRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsRouter, {
        get(target, method: keyof SettingsVacationsRouter) {
          if (router?.vacationsSettings && method in router.vacationsSettings) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.vacationsSettings?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<UserRouter>(UserRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const userRouter = new UserRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(userRouter, {
        get(target, method: keyof UserRouter) {
          if (router?.user && method in router.user) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.user?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });

  container
    .bind<VacationsRouter>(VacationsRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const vacationsRouter = new VacationsRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(vacationsRouter, {
        get(target, method: keyof VacationsRouter) {
          if (router?.vacations && method in router.vacations) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.vacations?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });
  container
    .bind<SettingsCompanyRouter>(SettingsCompanyRouter)
    .toDynamicValue((context: interfaces.Context) => {
      const settingsCompanyRouter = new SettingsCompanyRouterService(
        context.container.get<History>(History),
      );
      return new Proxy(settingsCompanyRouter, {
        get(target, method: keyof SettingsCompanyRouter) {
          if (router?.vacations && method in router.vacations) {
            /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
            return function (...args: any[]) {
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              router.vacations?.[method](...args);

              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              target[method](...args);
            };
          } else {
            return target[method];
          }
        },
      });
    });
};
