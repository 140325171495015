import { makeStyles, Theme } from '@material-ui/core';

import {
  ChipSize,
  ChipColor,
  SizeScheme,
  ColorScheme,
} from './ColoredChip.types';

const commonProps: (
  tokens: Theme['tokens'],
  px: (px: number) => number,
) => Pick<
  SizeScheme[keyof SizeScheme],
  'font' | 'borderRadius' | 'icon' | 'counter'
> = (tokens, px) => ({
  font: {
    size: tokens.typography.variant.body3.fontSize,
    lineHeight: tokens.typography.variant.body3.lineHeight,
  },
  borderRadius: tokens.radius.m,
  icon: {
    fontSize: px(17), // TODO - fix нет в токенах
    width: px(16),
    height: px(16),
    marginRight: tokens.spacing['12'],
    marginLeft: px(13), // TODO - fix нет в токенах
  },
  counter: {
    marginLeft: px(10), // TODO - fix нет в токенах
  },
});

const sizeScheme: (
  tokens: Theme['tokens'],
  px: (px: number) => number,
) => SizeScheme = (tokens, px) => ({
  [ChipSize.large]: {
    ...commonProps(tokens, px),
    height: px(48),
    padding: `14px ${tokens.spacing['16']}px`, // TODO - fix нет в токенах
  },
  [ChipSize.medium]: {
    ...commonProps(tokens, px),
    height: px(40),
    padding: `10px ${tokens.spacing['16']}px`, // TODO - fix нет в токенах
    icon: {
      ...commonProps(tokens, px).icon,
      marginRight: px(10), // TODO - fix нет в токенах
      marginLeft: px(11), // TODO - fix нет в токенах
    },
    counter: {
      marginLeft: tokens.spacing['8'],
    },
  },
  [ChipSize.small]: {
    ...commonProps(tokens, px),
    height: px(32),
    padding: `6px ${tokens.spacing['12']}px`, // TODO - fix нет в токенах
    icon: {
      ...commonProps(tokens, px).icon,
      marginRight: px(10), // TODO - fix нет в токенах
      marginLeft: px(11), // TODO - fix нет в токенах
    },
    counter: {
      marginLeft: tokens.spacing['8'],
    },
  },
  [ChipSize.extraSmall]: {
    ...commonProps(tokens, px),
    height: px(24),
    borderRadius: tokens.radius.s,
    icon: {
      fontSize: px(13), // TODO - fix нет в токенах
      width: px(13),
      height: px(13),
      marginRight: px(6), // TODO - fix нет в токенах
      marginLeft: px(6), // TODO - fix нет в токенах
    },
    padding: `${tokens.spacing['4']}px ${tokens.spacing['8']}px`,
    font: {
      size: tokens.typography.variant.caption.fontSize,
      lineHeight: tokens.typography.variant.caption.lineHeight,
    },
    counter: {
      marginLeft: tokens.spacing['4'],
    },
  },
});

const colorScheme: ColorScheme = {
  [ChipColor.gray]: {
    color: (p) => p.text.primary,
    bgColor: (p) => p.background.greyscalePrimary,
  },
  [ChipColor.darkgray]: {
    color: (p) => p.text.tertiary,
    bgColor: (p) => p.background.greyscaleTertiary,
  },
  [ChipColor.green]: {
    color: (p) => p.success.main,
    bgColor: (p) => p.success.light,
  },
  [ChipColor.orange]: {
    color: (p) => p.warning.main,
    bgColor: (p) => p.warning.light,
  },
  [ChipColor.red]: {
    color: (p) => p.error.main,
    bgColor: (p) => p.error.light,
  },
  [ChipColor.blue]: {
    color: (p) => p.blue.main,
    bgColor: (p) => p.blue.light,
  },
  [ChipColor.purple]: {
    color: (p) => p.review.main,
    bgColor: (p) => p.review.light,
  },
};

export const useStyles = makeStyles<
  Theme,
  {
    size: ChipSize;
    fullWidth: boolean;
    color: ChipColor;
    disabled: boolean;
  }
>(({ palette, tokens, px }) => ({
  container: ({ color, disabled }) => ({
    color: !disabled
      ? colorScheme[color].color(palette)
      : tokens.colors.text.light.tertirary,
    position: 'relative',
    width: 'fit-content',
  }),
  chip: ({ size, fullWidth, color, disabled }) => ({
    height: sizeScheme(tokens, px)[size].height,
    width: fullWidth ? '100%' : 'fit-content',
    backgroundColor: !disabled
      ? colorScheme[color].bgColor(palette)
      : tokens.colors.bg.greayscale.secondary,
    borderRadius: sizeScheme(tokens, px)[size].borderRadius,
    padding: sizeScheme(tokens, px)[size].padding,
    fontSize: sizeScheme(tokens, px)[size].font.size,
    lineHeight: sizeScheme(tokens, px)[size].font.lineHeight,
    '& .MuiSvgIcon-root': {
      fontSize: sizeScheme(tokens, px)[size].icon.fontSize,
      margin: 0,
      color: !disabled
        ? colorScheme[color].color(palette)
        : tokens.colors.text.light.tertirary,
    },
    '& .MuiChip-label': {
      padding: 0,
      overflow: 'visible',
    },
    '& .MuiChip-label .MuiTypography-root': {
      padding: 0,
      fontWeight: tokens.typography.variant.body3.fontWeight,
      fontSize: sizeScheme(tokens, px)[size].font.size,
      lineHeight: sizeScheme(tokens, px)[size].font.lineHeight,
      color: !disabled
        ? colorScheme[color].color(palette)
        : tokens.colors.text.light.tertirary,
    },
  }),
  chipLabel: ({ color, disabled }) => ({
    display: 'flex',
    alignItems: 'center',
    color: !disabled
      ? colorScheme[color].color(palette)
      : tokens.colors.text.light.tertirary,
  }),
  chipLabelTypography: ({ color, disabled }) => ({
    color: `${
      !disabled
        ? colorScheme[color].color(palette)
        : tokens.colors.text.light.tertirary
    } !important`,
  }),
  icon: ({ size }) => ({
    height: sizeScheme(tokens, px)[size].icon.height,
    width: sizeScheme(tokens, px)[size].icon.width,
  }),
  startIcon: ({ size }) => ({
    marginRight: sizeScheme(tokens, px)[size].icon.marginRight,
  }),
  endIcon: ({ size }) => ({
    marginLeft: sizeScheme(tokens, px)[size].icon.marginLeft,
  }),
  counter: ({ size }) => ({
    marginLeft: sizeScheme(tokens, px)[size].counter.marginLeft,
  }),
  counterSticked: {
    position: 'absolute',
    top: -tokens.spacing['8'],
    right: 0,
  },
}));
