import React, { useEffect, useCallback } from 'react';

import classNames from 'classnames';
import { useHistory, useLocation } from 'react-router-dom';
import Container from '@material-ui/core/Container';
import AppBar from '@material-ui/core/AppBar';

import { Box } from '@vk-hr-tek/ui/Box';
import { Grid } from '@vk-hr-tek/ui/Grid';
import { useInject } from '@vk-hr-tek/core/ioc';
import { Idle } from '@vk-hr-tek/core/idle';
import { Logger } from '@vk-hr-tek/core/logger';
import { DownloadNotification } from '@vk-hr-tek/ui/DownloadNotification';
import {
  selectDownloads,
  cancelDownloads,
} from '@vk-hr-tek/core/download-notification/slice';
import { VkIcon } from '@vk-hr-tek/ui/icons';
import { useIsDesktop } from '@vk-hr-tek/ui/hooks';
import { useMetaTitles } from '@vk-hr-tek/core/hooks';
import { useTheme } from '@vk-hr-tek/ui/Theme';

import { useSelector, useDispatch, useRole } from '@app/hooks';
import { UserRoleEnum } from '@app/types';

import { Chat } from '../Chat';
import { useAuth, useAuthType } from '../../auth';
import { logout } from '../../auth/slice';
import { AuthRouterService } from '../../auth/services';
import {
  selectCertificateStatus,
  selectUser,
  selectUserRepresentative,
  selectHasCompanySide,
} from '../../user';
import { User } from '../user';
import { Notifications } from '../notifications';
import { ErrorBoundary } from '../error-boundary';
import { Logo } from '../ui/Logo';
import { DisabledLKLayout } from '../DisabledLKLayout';
import { usePolicyPoll } from '../../policy/hooks/usePolicyPoll';
import { Footer } from '../ui/Footer';
import { selectServiceUnavailable } from '../../layout/slice';
import { PageError } from '../page';

import { vkLinks } from './GeneralLayout.helpers';
import { useStyles } from './GeneralLayout.styles';

interface GeneralLayoutProps {
  children: JSX.Element;
  navigation: JSX.Element;
  metaTitles: { title: string; href: string }[];
}

window.webim = {
  accountName: window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME,
  domain: `${window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME}.webim.ru`,
  location: 'hr_box',
};

export const GeneralLayout = ({
  children,
  navigation,
  metaTitles,
}: GeneralLayoutProps) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const classes = useStyles();
  const isAuthenticated = useAuth();
  const { pathname, search, hash } = useLocation();
  const authRouter = useInject(AuthRouterService);
  const logger = useInject(Logger);
  const isDesktop = useIsDesktop();
  const { status: authStatus, error: authError, authType } = useAuthType();

  const theme = useTheme();

  const [role, setRole] = useRole();

  const certificateStatus = useSelector(selectCertificateStatus);
  const isServiceUnavailable = useSelector(selectServiceUnavailable);
  const user = useSelector(selectUser);
  const userRepresentative = useSelector(selectUserRepresentative);
  const hasCompanySide = useSelector(selectHasCompanySide);

  const notifications = useSelector(selectDownloads);
  const handleCloseDownloadNotification = useCallback(() => {
    dispatch(cancelDownloads());
  }, [dispatch]);

  const handleIdle = useCallback(() => {
    dispatch(logout());
  }, [dispatch]);

  useMetaTitles(metaTitles);
  usePolicyPoll();

  useEffect(() => {
    if (!isAuthenticated && !pathname.startsWith('/auth/')) {
      authRouter.redirectToLogin();
    }
  }, [isAuthenticated, pathname, authRouter]);

  useEffect(() => {
    if (!hasCompanySide) return;

    const roleHash = hash.slice(1);
    if (roleHash !== UserRoleEnum.Company && roleHash !== UserRoleEnum.Employee)
      return;

    if (isAuthenticated && user && roleHash !== role) {
      setRole(roleHash);
      history.push({ pathname, search });
    }
  }, [
    isAuthenticated,
    user,
    pathname,
    search,
    hash,
    role,
    setRole,
    history,
    hasCompanySide,
  ]);

  if (isServiceUnavailable) {
    return <PageError status={503} />;
  }

  if (authStatus === 'failed') {
    return (
      <PageError
        status={authError?.status || 500}
        traceId={authError?.traceId}
        errorMessage="Произошла ошибка получения типа авторизации"
      />
    );
  }

  return (
    <ErrorBoundary logger={logger}>
      <div className={classes.root}>
        {isAuthenticated && authType === 'pass' && <Idle onIdle={handleIdle} />}
        {isAuthenticated &&
        authStatus === 'complete' &&
        certificateStatus === 'released' ? (
          <>
            <AppBar
              position="static"
              component="div"
              className={classes.myAppBar}
            >
              {theme === 'vk' ? (
                <>
                  <div className={classes.vkHeader}>
                    <a
                      href="https://vk.company"
                      rel="noopener noreferrer"
                      target="_blank"
                      className={classes.vkHeaderLogo}
                    >
                      <Box fontSize={16}>
                        <VkIcon />
                      </Box>
                    </a>
                    {vkLinks.map(({ label, url }) => (
                      <a
                        href={url}
                        key={url}
                        rel="noopener noreferrer"
                        target="_blank"
                        className={classes.vkHeaderLink}
                      >
                        {label}
                      </a>
                    ))}
                  </div>
                  <Box
                    className={classes.header}
                    py={
                      window.REACT_APP_VKHRTEK_LK_DISABLED &&
                      !userRepresentative
                        ? '16'
                        : '0'
                    }
                  >
                    <Box
                      justifyContent="space-between"
                      alignItems="center"
                      display="flex"
                    >
                      <Box alignItems="center" display="flex">
                        {isDesktop && (
                          <Box mr="32" display="flex" alignItems="center">
                            <Logo />
                          </Box>
                        )}
                        {navigation}
                      </Box>
                      <Box>
                        <User />
                      </Box>
                    </Box>
                  </Box>
                </>
              ) : (
                <div className={classes.content}>
                  <Container className={classes.appBar}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item xs="auto">
                        <Box alignItems="center" display="flex">
                          {isDesktop && (
                            <Box mr="32" display="flex">
                              <Logo />
                            </Box>
                          )}
                          {navigation}
                        </Box>
                      </Grid>
                      <Grid item xs="auto">
                        <User />
                      </Grid>
                    </Grid>
                  </Container>
                </div>
              )}
            </AppBar>

            <div className={classNames(classes.main, classes.content)}>
              <Container className={classes.container}>
                {window.REACT_APP_VKHRTEK_LK_DISABLED && !userRepresentative ? (
                  <DisabledLKLayout />
                ) : (
                  <>
                    <Notifications />
                    {children}
                    <DownloadNotification
                      notifications={notifications}
                      onClose={handleCloseDownloadNotification}
                    />
                  </>
                )}
              </Container>
            </div>

            {window.REACT_APP_VKHRTEK_WEBIM_ACCOUNT_NAME && <Chat />}

            <Footer />
          </>
        ) : (
          children
        )}
      </div>
    </ErrorBoundary>
  );
};
