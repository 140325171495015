import { injectable } from 'inversify';

import { HttpService } from '@vk-hr-tek/core/http';
import { UnmarshallerService } from '@vk-hr-tek/core/unmarshaller';
import { ValidationService } from '@vk-hr-tek/core/validation';

import {
  UserResponse,
  UserTelegramInitResponse,
  GenerateOneCVKAccessTokenResponse,
  UserPermissions,
  GetUserManagerSubstitutesCreateOptionsResponse,
  OneCVKAccessTokenStatusResponse,
  GetManageableCompaniesResponse,
} from '@app/gen/users';
import { CandidateResponse } from '@app/gen/candidates';
import { GetCompanyEmployeesResponse } from '@app/gen/settings';

import { VerifyCodeDto, GetCompanyEmployeeSubstitutesDto } from '../dto';
import {
  GetCompanyEventTypesDto,
  CreateSubstituteDto,
  RemoveSubstituteDto,
  SetDefaultAttorneyDto,
  ExportAttorneyDto,
} from '../dto';

@injectable()
export class UserApiService {
  constructor(
    private validator: ValidationService,
    private http: HttpService,
    private unmarshaller: UnmarshallerService,
  ) {}

  async getUser() {
    return this.unmarshaller.unmarshall(
      await this.http.get('/user'),
      UserResponse,
    );
  }

  async getCandidateUser() {
    return this.unmarshaller.unmarshall(
      this.http.get('/candidate'),
      CandidateResponse,
    );
  }

  async getUserPermissions() {
    return this.unmarshaller.unmarshall(
      await this.http.get(
        '/user/permissions',
        {},
        {
          withSide: true,
        },
      ),
      UserPermissions,
    );
  }

  async get1CTokenStatus() {
    return this.unmarshaller.unmarshall(
      await this.http.get('/user/onecvk/access_token/status'),
      OneCVKAccessTokenStatusResponse,
    );
  }

  async getTelegramSubscriptionUrl() {
    return this.unmarshaller.unmarshall(
      await this.http.post('/user/telegram/init'),
      UserTelegramInitResponse,
    );
  }

  async clearTelegramSubscription() {
    await this.http.post('/user/telegram/clear');
  }

  async certificateCreationInit() {
    await this.http.post('/user/certificate/_init');
  }

  async certificateCreationVerify(verifyCodeDto: VerifyCodeDto) {
    await this.validator.validateOrReject(verifyCodeDto);

    await this.http.post('/user/certificate/_verify', {
      code: verifyCodeDto.code,
    });
  }

  async getUnep() {
    const result = await this.http.getFile('/user/certificate/unep', {
      withSide: false,
    });

    return result;
  }

  async getToken1C() {
    return this.unmarshaller.unmarshall(
      await this.http.post('/user/onecvk/access_token/generate'),
      GenerateOneCVKAccessTokenResponse,
    );
  }

  async getCompanyEventTypes(getCompanyEventTypesDto: GetCompanyEventTypesDto) {
    await this.validator.validateOrReject(
      getCompanyEventTypesDto,
      GetCompanyEventTypesDto,
    );

    const { companyId, employeeId } = getCompanyEventTypesDto;

    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/user/manager_substitute/create_options`,
        {
          employee_id: employeeId,
          ...(companyId ? { company_id: companyId } : {}),
        },
        { withSide: true },
      ),
      GetUserManagerSubstitutesCreateOptionsResponse,
    );

    return result.event_types;
  }

  async getCompanyEmployeeSubstitutes(
    getCompanyEmployeeSubstitutesDto: GetCompanyEmployeeSubstitutesDto,
  ) {
    await this.validator.validateOrReject(
      getCompanyEmployeeSubstitutesDto,
      GetCompanyEmployeeSubstitutesDto,
    );

    const { companyId, employeeId, limit, offset, query } =
      getCompanyEmployeeSubstitutesDto;

    return this.unmarshaller.unmarshall(
      await this.http.get(
        `/company/${companyId}/substitute_employees/${employeeId}`,
        { limit, offset, query },
        { withSide: true },
      ),
      GetCompanyEmployeesResponse,
    );
  }

  async getSubstitutes() {
    const result = await this.unmarshaller.unmarshall(
      await this.http.get(
        `/user/get_manageable_companies`,
        {},
        { withSide: true },
      ),
      GetManageableCompaniesResponse,
    );

    return result.companies;
  }

  async addSubstitute(createSubstituteDto: CreateSubstituteDto) {
    await this.validator.validateOrReject(
      createSubstituteDto,
      CreateSubstituteDto,
    );

    const { employeeId, eventTypes, substituteId } = createSubstituteDto;

    return this.http.post(
      '/user/manager_substitute',
      {
        employee_id: employeeId,
        event_types: eventTypes,
        substitute_id: substituteId,
      },
      {
        withSide: true,
      },
    );
  }

  async removeSubstitute(removeSubstituteDto: RemoveSubstituteDto) {
    await this.validator.validateOrReject(
      removeSubstituteDto,
      RemoveSubstituteDto,
    );

    const { employeeId, eventTypeId, substituteId } = removeSubstituteDto;

    return this.http.delete(
      `/user/manager_substitute`,
      {
        employee_id: employeeId,
        event_type_id: eventTypeId,
        substitute_id: substituteId,
      },
      { withSide: true },
    );
  }

  async setDefaultAttorney(setDefaultAttorneyDto: SetDefaultAttorneyDto) {
    await this.validator.validateOrReject(
      setDefaultAttorneyDto,
      SetDefaultAttorneyDto,
    );

    const { attorneyId } = setDefaultAttorneyDto;

    return this.http.post(
      '/user/attorney/set_default',
      {
        attorney_id: attorneyId,
      },
      { withSide: true },
    );
  }

  async exportAttorney(exportAttorneyDto: ExportAttorneyDto) {
    await this.validator.validateOrReject(exportAttorneyDto, ExportAttorneyDto);

    const { attorneyId } = exportAttorneyDto;

    const result = await this.http.getFile(
      `/user/attorney/export?attorney_ids=${attorneyId}`,
    );

    return result;
  }

  async getAppQr() {
    const result = this.http.getFile(`/domain_qr`, {
      withSide: true,
    });

    return result;
  }
}
